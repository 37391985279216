import ig from "../assets/logo/footer/instagram.png";
import tw from "../assets/logo/footer/twitter.png";
import tt from "../assets/logo/footer/tiktok.png";
import x from "../assets/logo/footer/x.png";
import fb from "../assets/logo/footer/facebook.png";
import yt from "../assets/logo/youtube.png"

function Footer() {
  return (
    <footer className="footer_m">
     <div className="bottom">
      <div className="container bawah">
      <div class="col-sm-6">
     <div className="outsos">
    <a href="https://eci.id/" style={{textDecoration:"none", color:"white"}}>Belanja Online</a> &nbsp;&nbsp; <a  href="https://eci.id/careers"  style={{textDecoration:"none", color:"white"}}>Karir</a>
  <div className="sosmed">
          <div>
            <a href="https://www.instagram.com/electroniccityid/">
              <img src={ig} alt="instagram" className="iconf" />
            </a>
          </div>
          <div>
            <a href="https://www.facebook.com/ElectronicCityIndonesia/">
              <img src={fb} alt="facebook" className="iconf" />
            </a>
          </div>
          <div>
            <a href="https://x.com/electronic_city?lang=en">
              <img src={x} alt="twitter"  className="iconf" />
            </a>
          </div>
          <div>
            <a href="https://www.youtube.com/@ElectronicCityID/videos">
              <img src={yt} alt="youtube"  className="iconf" />
            </a>
          </div>
          <div>
            <a href="https://www.tiktok.com/@electroniccityid?lang=en">
              <img src={tt} alt="tiktok"  className="iconf" />
            </a>
          </div>
          
        
      </div>
      </div>
      </div>
          <div className="col-sm-6">        
            <p className="copy">&copy;&nbsp;Electronic City</p>
          </div>
    </div>
    </div>
    </footer>
  );
}

export default Footer;
