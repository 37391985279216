import alamat from "../../assets/images/alamat.png"
import cs from "../../assets/images/cs.png"
import wa from "../../assets/images/wa.png"
import email from "../../assets/images/email.png"
import telephone from "../../assets/images/telephone.png"
import "../../entity/Style/contact.css"
import { useLanguage } from "../../context/LanguageContext";

function Contact(){
    const { selectedLanguage } = useLanguage();

    return(
    <div className="ctc ">
        <div className="tiban ">
            <div className="container">
            <div className="judul ">
                <h2 className="h2con">{selectedLanguage === 'id' ?  'Hubungi Kami': 'Contact Us'}</h2>
                <center>
                    <hr className="h2r"/>
                </center>
               </div>
            <div class="footer-top">
                <div >
                    <div class="rawr">
                        <div class="footer-contact">
                            <h4>Corporate Secretary</h4>
                            <div>
                                <div className="contactisi">
                                    <div className="ctn_ic">
                                        <img src={alamat} alt="Alamat" className="iconc"></img>
                                    </div>
                                    <div className="icontac">
                                        <p>{selectedLanguage === 'id' ?  'Alamat': 'Address'}</p>
                                        <p >
                                            Jl. Jend. Sudirman Kav. 52-53, SCBD Lot. 22 Jakarta
                                            Selatan 12190
                                        </p>
                                    </div>
                                </div>
                                <div className="contactisi">
                                    <div className="ctn_ic">
                                    <img src={telephone} alt="Telephone" className="iconc"></img>
                                    </div>
                                    <div className="icontac">
                                        <p >Telephone</p>
                                        <p >021 - 5151177</p>
                                    </div>
                                </div>
                                <div className="contactisi">
                                    <div className="ctn_ic">
                                    <img src={email} alt="email" className="iconc"></img>
                                    </div>
                                    <div className="icontac">
                                        <p>Email</p>
                                      <a href={`mailto:corporatesecretary@electronic-city.co.id`}><p >corporatesecretary@electronic-city.co.id</p></a>  
                                    </div>
                                </div>
                            </div>
                            <h4>Customer Center <br /></h4>
                                <h4>{selectedLanguage === 'id' ?  '(Setiap Hari 10:00 - 22:00)': '(Everyday 10:00 - 22:00)'}</h4>
                            <div>
                                <div className="contactisi">
                                    <div className="ctn_ic">
                                        <img src={cs} alt="Customer Service" className="iconc"></img>
                                    </div>
                                    <div className="icontac">
                                        <p>Telephone</p>
                                        <p >1500032</p>
                                    </div>
                                </div>
                                <div className="contactisi">
                                    <div className="ctn_ic">
                                    <img src={wa} alt="Whatsapp" className="iconc"></img>
                                    </div>
                                    <div className="icontac">
                                        <p>SMS & Whatsapp</p>
                                        <p >0811 850000 32</p>
                                    </div>
                                </div>
                                <div className="contactisi">
                                    <div className="ctn_ic">
                                    <img src={email} alt="Email" className="iconc"></img>
                                    </div>
                                    <div className="icontac">
                                        <p>Email</p>
                                        <a href={`mailto:customer@electronic-city.co.id`}><p>customer@electronic-city.co.id</p></a>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="map_container">
                    <div className="map-responsive">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.281835066763!2d106.80873417480319!3d-6.226523093761568!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f121b6f79b65%3A0x3f173cda668762d4!2sElectronic%20City%20SCBD!5e0!3m2!1sid!2sid!4v1689818531994!5m2!1sid!2sid" width="550" height="500" frameborder="0" allowfullscreen></iframe>
                    </div>
                </div>
                </div>
                </div>
            </div>
        </div >
        </div>
        </div>
    );
}

export default Contact;