import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useLanguage } from "../../context/LanguageContext";
import image from "../../assets/images/tokoelek.jpeg";
import Belanja from "../../assets/images/eci.jpeg";
import ecity from "../../assets/images/home/bisnis/Ecityzen membership.jpg";
import { Link, NavLink, BrowserRouter as Router,Routes,Route, useLocation } from "react-router-dom";
import axios from 'axios';
import React, { useState, useEffect } from 'react';

import { BASE_API_URL } from '../../config/constants';



function Banner() {
  const [postData, setPostData] = useState([]);
  const [postBanner, setPostBanner] = useState([]);

  const [postData0, setPostData0] = useState([]);
  const [postData1, setPostData1] = useState([]);
  const [postData2, setPostData2] = useState([]);
  const [postData3, setPostData3] = useState([]);
  const [postData4, setPostData4] = useState([]);

  const { selectedLanguage } = useLanguage();

  const location = useLocation();
    useEffect(() => {
    const fetchPostData = async () => {
      try{
        if(location.pathname.startsWith ("/home")){
         
          const apiUrl0 = BASE_API_URL+'/posts/detail/post/*8A8C0D276B806DCC97F307D6C2AE31E9F10D7DC3';
          const response0 = await axios.get(apiUrl0);
          setPostData0(response0.data.post);
         
          const apiBanner = BASE_API_URL+'/posts/detail/post/da07aee2b65b66b48fab47e4d605b7c98dd422c841ab487b69dadc1b8fdfc7b5';
          const responseBanner = await axios.get(apiBanner);
          setPostBanner(responseBanner.data.post);

          const apiUrl = BASE_API_URL+'/posts/detail/post/*28D11E820EBB22B24035E49221D1E18E89F50CFB';
          const response = await axios.get(apiUrl);
          setPostData(response.data.post);
         
          const apiUrl1 = BASE_API_URL+'/posts/detail/post/*DADE80B013728E7D2CA0CEC47B0925E48C186B84';
          const response1 = await axios.get(apiUrl1);
          setPostData1(response1.data.post);

          const apiUrl4 = BASE_API_URL+'/posts/list/category?group=Bisnis%20Kami&page=1&limit=15&order=asc';
          const response4 = await axios.get(apiUrl4);
           setPostData2(response4.data.posts);
         
          const apiUrl2 = BASE_API_URL+'/posts/detail/post/*D572DADB250ED1F87134FF9644270F1BAD20A34F';
          const response2 = await axios.get(apiUrl2);
          setPostData3(response2.data.post);
          
          const apiUrl3 = BASE_API_URL+'/posts/detail/post/*538E269B3EEDD2D4EDFFFB98F46FD27661060D5B';
          const response3 = await axios.get(apiUrl3);
          setPostData4(response3.data.post);
          return
        }
        else if(location.pathname === ("/home/bisnis/belanja")){
          const apiUrl0 = BASE_API_URL+'/posts/detail/post/*8A8C0D276B806DCC97F307D6C2AE31E9F10D7DC3';
          const response0 = await axios.get(apiUrl0);
          setPostData(response0.data.post);

          const apiBanner = BASE_API_URL+'/posts/detail/post/da07aee2b65b66b48fab47e4d605b7c98dd422c841ab487b69dadc1b8fdfc7b5';
          const responseBanner = await axios.get(apiBanner);
          setPostData0(responseBanner.data.post);

          const apiUrl1 = BASE_API_URL+'/posts/detail/post/*DADE80B013728E7D2CA0CEC47B0925E48C186B84';
          const response1 = await axios.get(apiUrl1);
          setPostData1(response1.data.post);

          const apiUrl2 = BASE_API_URL+'/posts/detail/post/*D572DADB250ED1F87134FF9644270F1BAD20A34F';
          const response2 = await axios.get(apiUrl2);
          setPostData3(response2.data.post);

          const apiUrl = BASE_API_URL+'/posts/detail/post/*28D11E820EBB22B24035E49221D1E18E89F50CFB';
          const response = await axios.get(apiUrl);
          setPostData(response.data.post);
          
          const apiUrl3 = BASE_API_URL+'/posts/detail/post/*538E269B3EEDD2D4EDFFFB98F46FD27661060D5B';
          const response3 = await axios.get(apiUrl3);
          setPostData4(response3.data.post);
          return;
        }
        else if(location.pathname === ("/home/bisnis/cityzen")){
          const apiUrl0 = BASE_API_URL+'/posts/detail/post/*8A8C0D276B806DCC97F307D6C2AE31E9F10D7DC3';
          const response0 = await axios.get(apiUrl0);
          setPostData0(response0.data.post);

          const apiBanner = BASE_API_URL+'/posts/detail/post/da07aee2b65b66b48fab47e4d605b7c98dd422c841ab487b69dadc1b8fdfc7b5';
          const responseBanner = await axios.get(apiBanner);
          setPostData0(responseBanner.data.post);

          const apiUrl1 = BASE_API_URL+'/posts/detail/post/*DADE80B013728E7D2CA0CEC47B0925E48C186B84';
          const response1 = await axios.get(apiUrl1);
          setPostData1(response1.data.post);

          const apiUrl = BASE_API_URL+'/posts/detail/post/*538E269B3EEDD2D4EDFFFB98F46FD27661060D5B';
          const response = await axios.get(apiUrl);
          setPostData4(response.data.post);

          const apiUrl2 = BASE_API_URL+'/posts/detail/post/*D572DADB250ED1F87134FF9644270F1BAD20A34F';
          const response2 = await axios.get(apiUrl2);
          setPostData3(response2.data.post);
          
          const apiUrl3 = BASE_API_URL+'/posts/detail/post/*28D11E820EBB22B24035E49221D1E18E89F50CFB';
          const response3 = await axios.get(apiUrl3 );
          setPostData(response3.data.post);
          return;
        }
        else{
          const apiUrl0 = BASE_API_URL+'/posts/detail/post/*8A8C0D276B806DCC97F307D6C2AE31E9F10D7DC3';
          const response0 = await axios.get(apiUrl0);
          setPostData0(response0.data.post);
        
          const apiBanner = BASE_API_URL+'/posts/detail/post/da07aee2b65b66b48fab47e4d605b7c98dd422c841ab487b69dadc1b8fdfc7b5';
          const responseBanner = await axios.get(apiBanner);
          setPostData0(responseBanner.data.post);

          const apiUrl = BASE_API_URL+'/posts/detail/post/*28D11E820EBB22B24035E49221D1E18E89F50CFB';
          const response = await axios.get(apiUrl);
          setPostData(response.data.post);

          const apiUrl1 = BASE_API_URL+'/posts/detail/post/*DADE80B013728E7D2CA0CEC47B0925E48C186B84';
          const response1 = await axios.get(apiUrl1);
          setPostData1(response1.data.post);
        
          const apiUrl2 = BASE_API_URL+'/posts/list/category?group=Bisnis%20Kami&page=1&limit=15&order=asc';
          const response2 = await axios.get(apiUrl2);
           setPostData2(response2.data.posts);
          
           const apiUrl3 = BASE_API_URL+'/posts/detail/post/*D572DADB250ED1F87134FF9644270F1BAD20A34F';
           const response3 = await axios.get(apiUrl3);
           setPostData3(response3.data.post);
           
           const apiUrl4 = BASE_API_URL+'/posts/detail/post/*538E269B3EEDD2D4EDFFFB98F46FD27661060D5B';
           const response4 = await axios.get(apiUrl4);
           setPostData4(response4.data.post);
    
          return
        }
    } catch (error) {
      console.error('Error fetching data:', error);
      setPostData({});
      // setIsLoading(false); // Set isLoading ke false jika terjadi error
    }
    
    }
    fetchPostData();
  }, []);
  // const postArray = Array.isArray(postData1) ? postData1 : [];
  // const postArray1 = Array.isArray(postData3) ? postData3 : [];
  // const postArray2 = Array.isArray(postData4) ? postData4 : [];

  
  const BisnisComponent = () => {
    const isActive = (currentPath) => {
      return location.pathname === currentPath ? 'active' : '';
    };

    if (location.pathname === "/home") {
      return(
        <div className="contentt">
          {/* {postArray.map((post, index) => (
          <div key={index}> */}
          <div className={`gt ${isActive("/home")}`}>
          <img src= {postData.Img_path} alt={postData.Title} className="gambar"></img>
          <div className="subtitle1">
              <h3>{selectedLanguage === 'id' ?  postData.Title : 'About Electronic City'}</h3>
              <p dangerouslySetInnerHTML={{ __html: selectedLanguage === 'id' ? postData.Content_tx : postData.Content_tx_en }}></p>
              <br/>
                <div className="pll">
                  <a href={`/berita/bisnis/detail/${postData.Hash}`}>{selectedLanguage === 'id' ?  'Pelajari Lebih Lanjut' : 'Learn More'} <b>&rarr;</b></a>
                </div>
            </div>
        </div>
        {/* </div>
        ))} */}
    </div>
      );
      } else if (location.pathname === "/home/bisnis/belanja") {
        return(
          <div className="contentt">
            {/* {postArray1.map((post, index) => (
            <div key={index}> */}
            <div className={`gt ${isActive("/home/bisnis/belanja")}`}>
            <img src={postData3.Img_path} alt={postData3.Title} className="gambar"></img>
            <div className="subtitle1">
              <h3>{selectedLanguage === 'id' ?  postData3.Title : 'Ecommerce as Omni Channel'}</h3>
                <p dangerouslySetInnerHTML={{ __html: selectedLanguage === 'id' ? postData3.Content_tx : postData3.Content_tx_en }}></p>
                  <div className="pll">
                    <a href={`/berita/bisnis/detail/${postData3.Hash}`}>{selectedLanguage === 'id' ?  'Pelajari Lebih Lanjut' : 'Learn More'} <b>&rarr;</b></a>
                  </div>
              </div>
          </div>
          {/* </div>
          ))} */}
      </div>
        );
        }  else if (location.pathname === "/home/bisnis/cityzen") {
          return(
            <div className="contentt">
              {/* {postArray2.map((post, index) => (
              <div key={index}> */}
              <div className={`gt ${isActive("/home/bisnis/cityzen")}`}>
              <img src={postData4.Img_path} alt={postData4.Img_path} className="gambar"></img>
              <div className="subtitle1">
                  <h3>{selectedLanguage === 'id' ?  postData4.Title : 'Vision & Mission Electronic City'}</h3>
                  <p dangerouslySetInnerHTML={{ __html: selectedLanguage === 'id' ? postData4.Content_tx : postData4.Content_tx_en }}></p>
                  <br/>
                    <div className="pll">
                      <a href={`/berita/bisnis/detail/${postData4.Hash}`}>{selectedLanguage === 'id' ?  'Pelajari Lebih Lanjut' : 'Learn More'} <b>&rarr;</b></a>
                    </div>
                </div>
            </div>
            {/* </div>
            ))} */}
        </div>
          );
          } 
         else{
      return(
        <div className="contentt">
        <div className="gt">
        <img src={postData.Img_path} alt={postData.Title} className="gambar"></img>
        <div className="subtitle1">
        <p  className="subtitle2">
        <h3>{postData.Title}</h3>
            <p dangerouslySetInnerHTML={{ __html: postData.Content_tx }}></p>
            <br/>
          </p>
        <div className="pll">
            <a href={`/berita/bisnis/detail/${postData.Hash}`}>{selectedLanguage === 'id' ?  'Pelajari Lebih Lanjut' : 'Learn More'} <b>&rarr;</b></a>
        </div>
        </div>
      </div>
    </div>
      )
    }
  };
    return(
        <div>
            <div className="bg" style={{backgroundImage : `url(${postData0.Img_path})`}}>
              <div className="container">
                <div className="bgon">
                 
                            <div className="bktitle">
                              <div className="title">
                                <div dangerouslySetInnerHTML={{ __html: selectedLanguage === 'id' ? postData0.Title : '#LightUpYourMoment Trusted Modern Electronics Retail Store in Indonesia' }}></div>
                              </div>
                              <br />
                              <br />
                              <p className="subtitle">
                                <div dangerouslySetInnerHTML={{ __html: selectedLanguage === 'id' ? postData0.Content_tx : postData0.Content_tx_en }}></div>
                              </p>
                              <br />
                              <a href="#solusi">
                                <button className="bttn" type="button">
                                  <p>{selectedLanguage === 'id' ?  'Tentang Kami' : 'About Us'}</p>
                                  
                                </button>
                              </a>
                            </div>
                            <div className='bann'  style={{backgroundImage : `url(${postBanner.Img_path})`}}>
                  </div>
                </div>
                </div>
            </div>
            <div className='bgyt'>
            <div className='container'>
            <div className='yt'>
            <center>
                <iframe class="responsive-iframe container" src={postData1.File_upload} title="Electronic City Youtube" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </center>
            </div>
            </div>
        </div>
        <div id="solusi">
            <div className='container'>
                <div class="btn-h2bisnis">
                  <h2 class="h2bisnis">{selectedLanguage === 'id' ?  'Tentang Kami' : 'About Us'}</h2>
                   <center>
                       <hr />
                 </center>
                 <div className="btn" id="myBisnis">
                 
                    <NavLink  to={`/home`}>
                     <button className="button"  activeClassName="active" type="button">
                         {selectedLanguage === 'id' ?  postData.Title : 'About Electronic City'}
                     </button>
                     </NavLink>  
                  
                    <NavLink  to={`/home/bisnis/cityzen`}>
                     <button className="button "  activeClassName="active" type="button">
                         {selectedLanguage === 'id' ?  postData4.Title : 'Vision & Mission Electronic City'}
                     </button>
                     </NavLink>

                     <NavLink  to={`/home/bisnis/belanja`}>
                     <button className="button "  activeClassName="active" type="button">
                        {selectedLanguage === 'id' ?  postData3.Title : 'Ecommerce as Omni Channel'}
                     </button>
                     </NavLink>
                 </div>
                 <div> 
                  <BisnisComponent />
                 </div>
             </div>
             </div>
             <div className='container'>
                <div className="md_bisnis ">
                <h2 class="h2bisnis">{selectedLanguage === 'id' ?  'Tentang Kami' : 'About Us'}</h2>
                   <center>
                       <hr />
                 </center>
                 <Swiper
      // install Swiper modules
      modules={[Navigation, Pagination, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      // scrollbar={{ draggable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
      >
  
      <SwiperSlide> 
                <img src={postData.Img_path} alt={postData.Title} className="gambar"></img>
                    <div className="subtitle1">
                    <p className="txben">
                    <h4>{postData.Title}</h4>
                    <p dangerouslySetInnerHTML={{ __html: postData.Content_tx }}>
                     
                    </p>
            <br />
                    </p>
              <div className="pll">
                <a href="https://eci.id/">{selectedLanguage === 'id' ?  'Pelajari Lebih Lanjut' : 'Learn More'} <b>&rarr;</b></a>
              </div>
                </div>
      </SwiperSlide>
      <SwiperSlide> 
                <img src={postData3.Img_path} alt={postData3.Title} className="gambar"></img>
                    <div className="subtitle1">
                    <p className="txben">
            <h4>{postData3.Title}</h4>
            <p dangerouslySetInnerHTML={{ __html: postData3.Content_tx }}>
              
            </p>
            <br />
           
              </p>
              <div className="pll">
                <a href="https://eci.id/">{selectedLanguage === 'id' ?  'Pelajari Lebih Lanjut' : 'Learn More'} <b>&rarr;</b></a>
              </div>
                </div>
      </SwiperSlide>
      <SwiperSlide> 
                <img src={postData4.Img_path} alt={postData4.Title} className="gambar"></img>
                    <div className="subtitle1">
                    <p className="txben">
            <h4>{postData4.Title}</h4>
            <p dangerouslySetInnerHTML={{ __html: postData4.Content_tx }}>    
            </p>
            <br />  
              </p>
              <div className="pll">
                <a href="https://eci.id/">{selectedLanguage === 'id' ?  'Pelajari Lebih Lanjut' : 'Learn More'} <b>&rarr;</b></a>
              </div>
                </div>
      </SwiperSlide>
    
      
    </Swiper>
      
            </div>
            </div>
            </div>
        </div>
        
    );
}

export default Banner;

