// import { StrictMode } from "react";
// import { createRoot } from "react-dom/client";
// import 'bootstrap/dist/css/bootstrap.min.css';

// import App from "./App";

// const rootElement = document.getElementById("root");
// const root = createRoot(rootElement);

// root.render(
//   <StrictMode>
//     <App />
//   </StrictMode>
// );
import React from "react";
import ReactDOM from "react-dom";
import BenefitBelanja from "./entity/Home/BenefitBelanja";
import Home from "./entity/Home/Home";
import { LanguageProvider } from './context/LanguageContext';
// import Tenka from "./components/tenka/Tenka";
import "./entity/Navbar.css";
import "./entity/Style/Fit.css";
// import "./style/Main.css";

ReactDOM.render(
  <LanguageProvider>
    <Home />
  </LanguageProvider>,
  document.getElementById('root')
);