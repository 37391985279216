import React from 'react';

function Imagesl (){
return(
<div>
<div>
  <h2>Halaman ini tidak tersedia</h2>
</div>
<div>
  <button>kembali kembali</button>
</div>
</div>
)
}

export default Imagesl; 