import React, { useState, useEffect } from 'react';
import Navbar from "../Navbar";
import "../Navbar.css";
import { useLanguage } from "../../context/LanguageContext";
import Footer from '../Footer';
import '../About/Tenkapage.css';
import { useNavigate, useLocation, Link} from "react-router-dom";
import axios from 'axios';
import { BASE_API_URL } from '../../config/constants';

const Newspage = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [newsData, setNewsData] = useState([]);
    const [postData, setPostData] = useState([]);
    const [postData1, setPostData1] = useState([]);
    const [postData2, setPostData2] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(8);

    const { selectedLanguage } = useLanguage();
  
    const handleClick = (number) => {
      setCurrentPage(number);
    };  

    const toggleMobileMenu = () => {
      setIsMobileMenuOpen(!isMobileMenuOpen);
    // Tambahkan perubahan ini
    };

    const location = useLocation();
  useEffect(() => {
    const fetchPostData = async () => {
      try {
        if(location.pathname === "/berita"){
          const apiUrl = BASE_API_URL+'/posts/list/category?group=BERITA&page=1&limit=100&order=desc';
          const response = await axios.get(apiUrl);
          setPostData(response.data.posts);
          const apiUrlTrend = BASE_API_URL+'/posts/list/category?group=BERITA&page=1&limit=1&order=desc';
          const respons = await axios.get(apiUrlTrend);
          setNewsData(respons.data.posts);
          return
        }
        else if(location.pathname.startsWith("/berita/detail/")){
          const hash = location.pathname.replace("/berita/detail/", "");
          const apiUrl1 = BASE_API_URL+'/posts/list/category?group=BERITA&page=1&limit=100&order=desc';
          const respons = await axios.get(apiUrl1);
          setPostData2(respons.data.posts);
          if (hash) {
            const apiUrl = BASE_API_URL+`/posts/detail/post/${hash}`;
            const response = await axios.get(apiUrl);
            setPostData(response.data.post);
          }
          return;
        }
        else if(location.pathname.startsWith("/berita/bisnis/detail/")){
          const hash = location.pathname.replace("/berita/bisnis/detail/", "");
          const apiUrl2 = BASE_API_URL+'/posts/list/category?group=Bisnis%20Kami&page=1&limit=15&order=asc';
          const response2 = await axios.get(apiUrl2);
          setPostData1(response2.data.posts);
          if (hash) {
            const apiUrl = BASE_API_URL+`/posts/detail/post/${hash}`;
            const response = await axios.get(apiUrl);
            setPostData(response.data.post);
          }
          return;
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setPostData({});
        // setIsLoading(false); // Set isLoading ke false jika terjadi error
      }
    };
    fetchPostData();
  }, []);
  const postArray  = Array.isArray(postData)  ? postData  : [];
  const postNews   = Array.isArray(newsData)  ? newsData  : [];
  const postArray1 = Array.isArray(postData1) ? postData1 : [];
  const postArray2 = Array.isArray(postData2) ? postData2 : [];

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = postArray.slice(indexOfFirstPost, indexOfLastPost);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(postArray.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }

    const CustomKonten = () => {
      const location = useLocation();
      const maxLength = 300

      const ok = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || '';
      };

  
      if (location.pathname === "/berita") {
        return (
          <>
            {postNews.map((post, index) => (
              <div key={index}>
                <Link to={`/berita/detail/${post.Hash}`}>
                  <div className='content_berita'>
                    <div>
                      <img src={post.Img_path} alt={post.Title}></img>
                     
                    </div>
                    <div>
                      <p className='title-news'>{post.Title}</p>
                      <p className='tanggal_news'>{post.Create_date}</p>
                      <br/>
                      <p>
                      {ok(post.Content_tx).slice(0  + '...', maxLength )}
                      </p>
                      <Link className='d_tel' to={`/berita/detail/${post.Hash}`}>Baca Selengkapnya</Link>
                    </div>
                    
                  </div>
                </Link>
              </div>
            ))}
            <div className='more'>
            
            {currentPosts.map((post,index) => (        
                <div key={index}>
                <Link to={`/berita/detail/${post.Hash}`}>
                <div className='more_news'>
                  <div>
                    <img src={post.Img_path}></img>
                  </div>
                  <div>
                    <h4>{post.Title}</h4>
                    <p className='tanggal_news'>{post.Create_date}</p>
                    <p>{ok(post.Content_tx).slice(120, maxLength)}...</p>
                    <br/>
                    <Link className='d_tel' to={`/berita/detail/${post.Hash}`}>Baca Selengkapnya</Link>
                 </div>
                </div>
                </Link>
                </div>
            ))}
            </div>
            <div className='pagination-container'>
            <div className="pagination" style={{ textAlign: 'right' }}>
            <ul>
              {pageNumbers.map((number) => (
                <li key={number}>
                  <button 
                  className={currentPage === number ? 'active' : ''}
                  onClick={() => handleClick(number)}>
                    {number}
                  </button>
                </li>
              ))}
            </ul>
          </div>   
          </div>                                                                                                                                                                                             </>
        );
      } 
      else if (location.pathname.startsWith("/berita/detail/")) {
        const hash = location.pathname.replace("/berita/detail/", "");
        const selectedPost = postArray.find(postData => postData.Hash === hash);
        if (selectedPost) {
          return (
            <>
              <div className="bk_berita">
              <a href="/berita">&#8592; {selectedLanguage === 'id' ?  'Kembali' : 'Back'}</a>
              </div>
              <div className="d_news">
                <div>
                  <h3>{selectedPost.Title}</h3>
                </div>
                <div>
                  <img src={selectedPost.Img_path} alt={selectedPost.Title}></img>
                </div>
                <div>
                  <p dangerouslySetInnerHTML={{ __html: selectedPost.Content_tx}}></p>
                </div>
              </div>
            </>                                                                                                                                                                                                                                                                                                                     
          );
        } 
        else if (hash) {
          const hash = location.pathname.replace("/berita/detail/", "");
          const selectedPost = postArray2.find(postData2 => postData2.Hash === hash);
          if (selectedPost) {
            return (
              <>
                <div className="bk_berita">
                <a href="/berita">&#8592; {selectedLanguage === 'id' ?  'Kembali' : 'Back'}</a>
                </div>
                <div className="d_news">
                  <div>
                    <h2>{selectedPost.Title}</h2>
                  </div>
                  <div>
                    <img src={selectedPost.Img_path} alt={selectedPost.Title}></img>
                  </div>
                  <div>
                    <p  dangerouslySetInnerHTML={{ __html: selectedPost.Content_tx}}></p>
                  </div>
                </div>
              </>
            );
          } 
        }
      }
      else if (location.pathname.startsWith("/berita/bisnis/detail/")) {
        const hash = location.pathname.replace("/berita/bisnis/detail/", "");
        const selectedPosta = postArray1.find(postData1 => postData1.Hash === hash);
        if (selectedPosta) {
          return (
            <>
              <div className="bk_berita">
              <a href="/berita">&#8592; {selectedLanguage === 'id' ?  'Kembali' : 'Back'}</a>
              </div>
              <div className="d_news">
                <div>
                  <h2>
                    {
                    selectedPosta.Title === 'Visi & Misi Electronic City' ? 
                      (selectedLanguage === 'id' ? 'Visi & Misi Electronic City' : 'Vision & Mission Electronic City') :
                    selectedPosta.Title === 'Tentang Electronic City' ? 
                      (selectedLanguage === 'id' ? 'Tentang Electronic City' : 'About Electronic City') :
                    selectedPosta.Title === 'E-Commerce Sebagai Omni Channel' ? 
                      (selectedLanguage === 'id' ? 'E-Commerce Sebagai Omni Channel' : 'E-commerce as Omni Channel') :
                      ''
                  }
                  </h2>
                </div>
                <div>
                  <img src={selectedPosta.Img_path} alt={selectedPosta.Title}></img>
                </div>
                <div>
                  <p dangerouslySetInnerHTML={{ __html: selectedLanguage === 'id' ? postData.Content_tx : postData.Content_tx_en }}></p>
                </div>
              </div>
            </>
          );
          
        } else {
          return (
            <div className="bk_berita">
            <a href="/berita">&#8592; {selectedLanguage === 'id' ?  'Kembali' : 'Back'}</a>
            <h1>Halaman Kosong</h1>
          </div>
          );
        }
      }
    }
    return (
      <div>
        <div className='navbar_m'>
          <div className='bantiban'>
            <Navbar isMobileMenuOpen={isMobileMenuOpen} toggleMobileMenu={toggleMobileMenu} />
            <div className=' container hh'>
              <p>
                {selectedLanguage === 'en' ? 'News' : 'Berita'}
              </p>
            </div>
          </div>
        </div>
        <div className="container isi_berita">
            <CustomKonten />              
        </div>
        <div className='footer_m'>
          <Footer />
        </div>
            </div>
    );
  };
  
  export default Newspage;