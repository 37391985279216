import React, { useState } from 'react';
import './ToggleButton.css';
import { useLanguage } from '../context/LanguageContext';

const ToggleButton = () => {
  const { selectedLanguage, toggleLanguage } = useLanguage();

  return (
    <div className="floating-toggle-button">
      <div className="switch">
        <input
          id="language-toggle"
          className="check-toggle check-toggle-round-flat"
          type="checkbox"
          checked={selectedLanguage === 'en'}
          onChange={toggleLanguage}
        />
        <label htmlFor="language-toggle"></label>
        <span className="on">ID</span>
        <span className="off">EN</span>
      </div>
    </div>
  );
}

export default ToggleButton;
